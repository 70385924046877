@tailwind base;
@tailwind components;
@tailwind utilities;

@layer base {
  :root {
    --background: 0 0% 100%;
    --foreground: 240 10% 3.9%;
    --card: 0 0% 100%;
    --card-foreground: 240 10% 3.9%;
    --popover: 0 0% 100%;
    --popover-foreground: 240 10% 3.9%;
    --primary: 240 5.9% 10%;
    --primary-foreground: 0 0% 98%;
    --secondary: 240 4.8% 95.9%;
    --secondary-foreground: 240 5.9% 10%;
    --muted: 240 4.8% 95.9%;
    --muted-foreground: 240 3.8% 46.1%;
    --accent: 240 4.8% 95.9%;
    --accent-foreground: 240 5.9% 10%;
    --destructive: 0 84.2% 60.2%;
    --destructive-foreground: 0 0% 98%;
    --border: 240 5.9% 90%;
    --input: 240 5.9% 90%;
    --ring: 240 5.9% 10%;
    --radius: 0.5rem;
    --chart-1: 12 76% 61%;
    --chart-2: 173 58% 39%;
    --chart-3: 197 37% 24%;
    --chart-4: 43 74% 66%;
    --chart-5: 27 87% 67%;
  }

  .dark {
    --background: 240 10% 3.9%;
    --foreground: 0 0% 98%;
    --card: 240 10% 3.9%;
    --card-foreground: 0 0% 98%;
    --popover: 240 10% 3.9%;
    --popover-foreground: 0 0% 98%;
    --primary: 0 0% 98%;
    --primary-foreground: 240 5.9% 10%;
    --secondary: 240 3.7% 15.9%;
    --secondary-foreground: 0 0% 98%;
    --muted: 240 3.7% 15.9%;
    --muted-foreground: 240 5% 64.9%;
    --accent: 240 3.7% 15.9%;
    --accent-foreground: 0 0% 98%;
    --destructive: 0 62.8% 30.6%;
    --destructive-foreground: 0 0% 98%;
    --border: 240 3.7% 15.9%;
    --input: 240 3.7% 15.9%;
    --ring: 240 4.9% 83.9%;
    --chart-1: 220 70% 50%;
    --chart-2: 160 60% 45%;
    --chart-3: 30 80% 55%;
    --chart-4: 280 65% 60%;
    --chart-5: 340 75% 55%;
  }
}


@layer base {
  * {
    @apply border-border;
  }
  body {
    @apply antialiased bg-background text-foreground;
  }

  .chat-input-context {
    @apply pl-4 my-1 mx-0 relative after:content-[''] after:block after:absolute after:left-0 after:top-0 after:bottom-0 after:w-1 after:bg-gray-300 after:rounded-md;
  }
}

@layer utilities {
  .border-beam-mask {
    pointer-events: none;
    position: absolute;
    inset: 0;
    border-radius: inherit;
    border: 1px solid transparent;
    mask-clip: padding-box, border-box;
    -webkit-mask-clip: padding-box, border-box;
    -webkit-mask-composite: intersect;
    mask-composite: intersect;
    mask-image: linear-gradient(transparent, transparent),
      linear-gradient(#000, #000);
    -webkit-mask-image: linear-gradient(transparent, transparent),
      linear-gradient(#000, #000);
  }
}

@layer utilities {
  /* Field Sizing */
  .field-sizing-fixed {
    field-sizing: fixed;
  }

  .field-sizing-content {
    field-sizing: content;
  }

  .checkerboard {
    background: repeating-conic-gradient(#808080 0% 25%, transparent 0% 50%) 50% / 20px 20px
  }
}
