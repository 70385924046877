html,
body {
  height: 100%;
}

@font-face {
  font-family: 'PoppinsRegular';
  src: url(../assets/fonts//Poppins-Regular.ttf) format('truetype');
}

@font-face {
  font-family: 'PoppinsBold';
  src: url(../assets/fonts//Poppins-Bold.ttf) format('truetype');
}

@font-face {
  font-family: 'PoppinsSemiBold';
  src: url(../assets/fonts//Poppins-SemiBold.ttf) format('truetype');
}

@font-face {
  font-family: 'PoppinsMedium';
  src: url(../assets/fonts//Poppins-Medium.ttf) format('truetype');
  font-weight: 500;
  font-style: normal;
}

body {
  margin: 0;
  font-family: 'PoppinsRegular', -apple-system, BlinkMacSystemFont, 'Segoe UI',
    'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans',
    'Helvetica Neue', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: white;
}

#root {
  display: flex;
  height: 100%;
  width: 100%;
}

/**
 * Dot Flashing - Spinner
 */
.dot-flashing {
  position: relative;
  width: 20px;
  height: 20px;
  border-radius: 15px;
  background-color: #000000;
  color: #000000;
  animation: dotFlashing 1s infinite linear alternate;
  animation-delay: 0.5s;
}

.dot-flashing::before,
.dot-flashing::after {
  content: '';
  display: inline-block;
  position: absolute;
  top: 0;
}

.dot-flashing::before {
  left: -25px;
  width: 20px;
  height: 20px;
  border-radius: 10px;
  background-color: #000000;
  color: #000000;
  animation: dotFlashing 1s infinite alternate;
  animation-delay: 0s;
}

.dot-flashing::after {
  left: 25px;
  width: 20px;
  height: 20px;
  border-radius: 10px;
  background-color: #000000;
  color: #000000;
  animation: dotFlashing 1s infinite alternate;
  animation-delay: 1s;
}

@keyframes dotFlashing {
  0% {
    background-color: #000000;
  }
  50%,
  100% {
    background-color: #ebe6ff;
  }
}
