.uppy-Dashboard-AddFiles {
  height: calc(100% - 14px);
  margin: 7px;
  border: none !important;
}

.uppy-StatusBar.is-waiting .uppy-StatusBar-actionBtn--upload:hover {
  background-color: #000000 !important;
  color: #fff !important;
}

.uppy-StatusBar.is-waiting .uppy-StatusBar-actionBtn--upload {
  color: #000000 !important;
  background-color: transparent !important;
  border: 1px solid #000000 !important;
}

.uppy-Dashboard-browse {
  color: #000000 !important;
  cursor: pointer;
}

.uppy-DashboardContent-addMore {
  color: #fff !important;
  background-color: #000000 !important;
}

.uppy-DashboardContent-back,
.uppy-DashboardContent-save {
  color: #fff !important;
  background-color: #000000 !important;
}

.uppy-StatusBar-actionBtn {
  color: #fff !important;
  background-color: #000000 !important;
}

.highlight {
  padding: 1rem;
  margin-bottom: 1rem;
  background-color: #f8f9fa;
}

.display-row {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
}

.tab-group {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  border-top: 1px solid #eeeeee;
  border-bottom: 1px solid #eeeeee;
  padding: 9px 15px;
}

.copy-icon {
  width: 38;
  height: 21px;
}

.icon-button-container {
  border-radius: 6px;
  border: 1px solid #949494;
  padding: 6px 12px;
}

.tab-child {
  padding: 20px 20px;
}

.text-result-container {
  margin-top: 30px;
}

.open-close-icon {
  justify-content: normal;
}

.input-container {
  margin-top: 20px;
}

.advanced-search-button {
  margin-top: 20px;
  display: flex;
  align-items: center;
  justify-content: flex-end;
}

.new-proposal-input {
  margin: 10px 0;
  margin-top: 5px;
  margin-bottom: 14px;
}

.full-screen {
  width: 100%;
  height: 100vh;
}

.full-width {
  width: 100%;
}

.full-height {
  height: 100vh;
}

.new-proposal-page-wrapper {
  display: flex;
}

.display-center {
  width: 100%;
  justify-content: center;
  align-items: center;
}

.align-horizontal-center {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.new-propsal-button {
  margin-top: 20px;
  display: flex;
  justify-content: flex-end;
  align-items: center;
}

.button-spacing {
  padding: 10px;
}

.new-proposal-input-size {
  max-width: 500px;
}
