.section-editor {
  position: absolute;
  top: 0;
  left: 0;
  z-index: 10;
  max-width: 400px;
  width: 100%;
  opacity: 0;
  background-color: #fff;
  box-shadow: 0 5px 10px rgba(0, 0, 0, 0.3);
  border-radius: 8px;
  transition: opacity 0.5s;
  will-change: transform;
  padding: 10px;
}

.floating-section {
  border-radius: 4px;
  padding: 2px 5px;
  cursor: pointer;
  opacity: 0;
  position: absolute;
  left: 0;
  top: 0;
  will-change: transform;
  font-size: 16px;
}
